<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs10 class="mb-5">
        <h3 class="accent--text">Welcome back,</h3>
        <h1 class="f-alfa primary--text">{{ USER.user.name }}</h1>
      </v-flex>
      <v-flex xs2 class="d-flex align-center justify-end">
        <v-btn class="primary--text" @click="logout" rounded
          ><v-icon class="mr-2">mdi-power</v-icon>Logout</v-btn
        >
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="departments">
        <div class="menu-item d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-chair-school</v-icon>
          <h3 class="primary--text fw-bold ml-3">Departments</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="roles">
        <div class="menu-item d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-account-star</v-icon>
          <h3 class="primary--text ml-3">Roles</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="accounts">
        <div class="menu-item d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-account-multiple</v-icon>
          <h3 class="primary--text ml-3">Users</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-shield-check</v-icon>
          <h3 class="primary--text ml-3">Permissions</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent"
            >mdi-chart-box-outline</v-icon
          >
          <h3 class="primary--text ml-3">Leads</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="projects">
        <div class="menu-item d-flex align-center">
          <v-icon class="menu-icon" color="accent"
            >mdi-archive-check-outline</v-icon
          >
          <h3 class="primary--text ml-3">Projects</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="tasks">
        <div class="menu-item d-flex align-center">
          <v-icon class="menu-icon" color="accent"
            >mdi-calendar-check-outline</v-icon
          >
          <h3 class="primary--text ml-3">Tasks</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-chart-bar</v-icon>
          <h3 class="primary--text ml-3">Reports</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-account-check</v-icon>
          <h3 class="primary--text ml-3">Attendance</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent"
            >mdi-text-box-multiple-outline</v-icon
          >
          <h3 class="primary--text ml-3">Leave Applications</h3>
        </div>
      </v-flex>

      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent"
            >mdi-help-circle-outline</v-icon
          >
          <h3 class="primary--text ml-3">Help</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled d-flex align-center">
          <v-icon class="menu-icon" color="accent">mdi-finance</v-icon>
          <h3 class="primary--text ml-3">Perfomance Analysis</h3>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters(["USER"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("SET_USER", null);
      this.$toastr.s("LOGGED OUT", "Logged out of Ingenious Engine");
      this.$router.push("/");
    },
    roles() {
      this.$router.push("/roles");
    },
    departments() {
      this.$router.push("/departments");
    },
    accounts() {
      this.$router.push("/accounts");
    },
    projects() {
      this.$router.push("/projects");
    },
    booking() {
      this.$router.push("/bookings");
    },
    users() {
      this.$router.push("/accounts");
    },
    tasks() {
      this.$router.push("/tasks");
    },
    customerService() {
      this.$router.push("/support/cases");
    },
  },
};
</script>
  
  <style scoped>
.menu-item {
  cursor: pointer;
  border: 2px solid #e09145;
  border-radius: 50px;
  padding: 10px 10px;
  box-shadow: 5px 5px 10px rgba(224, 145, 69, 1);
}
.menu-item-disabled {
  cursor: not-allowed;
  border: 2px solid #e09145;
  border-radius: 50px;
  padding: 10px 10px;
  box-shadow: 5px 5px 10px rgba(224, 145, 69, 1);
}
.menu-icon {
  border: 2px solid #fcd9b8;
  border-radius: 50%;
  font-size: 18pt;
  padding: 10px;
}
.fw-bold {
  font-weight: 700 !important;
}
h3 {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
  